<template>
  <div class="subscription">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="main-title">
            <h2 class="mb-4">Chargebee Subscription Details</h2>
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <b-table class="subscription-table" :fields="fields" :items="subscriptionData">
                    <template #cell(Plan_ID)="data">
                      <span>{{
                        splitText(data.item.subscription.plan_id)
                      }}</span>
                    </template>

                    <template #cell(Plan_amount)="data">
                      <span>{{ planAmount(data.item.subscription.plan_amount) }}
                        {{ data.item.subscription.currency_code }}</span>
                    </template>

                    <template #cell(Current_term_end)="data">
                      <span>{{
                        getDate(data.item.subscription.current_term_end)
                      }}</span>
                    </template>

                    <template #cell(Billed_every)="data">
                      <span>{{ data.item.subscription.billing_period }}
                        {{ data.item.subscription.billing_period_unit }}</span>
                    </template>

                    <template #cell(Status)="data">
                      <div v-if="data.item.subscription.status === 'active'">
                        <span class="status-btn btn-primary">{{
                          data.item.subscription.status
                        }}</span>
                      </div>

                      <div v-if="data.item.subscription.status === 'non renewing'">
                        <span class="status-btn btn-warning">{{
                          data.item.subscription.status
                        }}</span>
                      </div>

                      <div v-if="data.item.subscription.status === 'cancelled'">
                        <span class="status-btn btn-danger">{{
                          data.item.subscription.status
                        }}</span>
                      </div>
                    </template>
                    <template #cell(delete)="data">
                      <b-button variant="primary" v-if="!data.item.subscription.deleted"
                        @click="OpenPauseOrDeletePopup(data.item)">
                        Cancel Subscription
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
          <b-modal size="lg" centered v-model="showPauseOrDeletePopup" hide-footer="true" hide-header-close
            :no-close-on-backdrop="true" title="Pause or delete account">
            <span class="space" @click="closePopUp">
              <img src="@/assets/images/icons/close.svg" alt="" />
            </span>

            <div class="sub-modal pause-sub">
              <template>
                <div class="mb-2 ">
                  <b-form-group>
                    <b-form-radio-group id="radio-slots" v-model="selected">
                      <b-form-radio name="some-radios" value="pauseAccount">
                        <h4>
                          Temporarily pause my account and keep my credits &
                          data
                        </h4>
                        <p>
                          You will not have access to Datagma, but the data
                          found and the credits will wait for you.
                        </p>
                      </b-form-radio>

                      <b-form-radio class="mt-3" name="some-radios" value="deleteAccount">
                        <h4>
                          Permanently delete this account and all my credits
                        </h4>
                        <p>
                          You will not have access to Datagma, and all data and
                          credits in the account<br />
                          will be permanently deleted.
                        </p>
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>

                  <div class="text-right button-back">
                    <b-button variant="success mr-2" @click="cancelPopup()">Cancel</b-button>
                    <b-button v-if="selected === 'pauseAccount'" variant="outline-primary mr-4" @click="pauseStep()"
                      :disabled="getGreyClass()">Pause Account</b-button>
                    <b-button v-else variant="outline-primary mr-4" @click="secondStep()"
                      :disabled="getGreyClass()">Delete Account</b-button>
                  </div>
                </div>
              </template>
            </div>
          </b-modal>
          <b-modal size="lg" centered v-model="showCancelPopupStep1" hide-footer="true" hide-header-close
            :no-close-on-backdrop="true" title="Why have you decided to cancel your subscription?"><span class="space"
              @click="closePopUp">
              <img src="@/assets/images/icons/close.svg" alt="" />
            </span>
            <div class="sub-modal Cancel-sub">
              <template>
                <div class="mt-4">
                  <b-form-checkbox id="checkbox-1" v-model="step1.notEnoughData" name="checkbox-1">
                    Not enough data
                  </b-form-checkbox>
                </div>

                <div>
                  <b-form-checkbox id="checkbox-2" v-model="step1.tooComplicated" name="checkbox-2">
                    Too Complicated
                  </b-form-checkbox>
                </div>

                <div>
                  <b-form-checkbox id="checkbox-3" v-model="step1.noTimeToOptimise" name="checkbox-3">
                    Don't have enough time to optimize Datagma or need to
                    rethink how to implement it
                  </b-form-checkbox>
                </div>

                <div>
                  <b-form-checkbox id="checkbox-4" v-model="step1.projectFinished" name="checkbox-4">
                    My project has finished and I no longer need Datagma
                  </b-form-checkbox>
                </div>

                <div>
                  <b-form-checkbox id="checkbox-5" v-model="step1.poorFunctionality" name="checkbox-5">
                    Poor functionality
                  </b-form-checkbox>
                </div>

                <div>
                  <b-form-checkbox id="checkbox-6" v-model="step1.tooExpensive" name="checkbox-6">
                    It's too expensive
                  </b-form-checkbox>
                </div>

                <div>
                  <b-form-checkbox id="checkbox-7" v-model="step1.goWithProvider" name="checkbox-7">
                    Going with a different provider
                  </b-form-checkbox>
                </div>

                <div>
                  <b-form-checkbox id="checkbox-8" v-model="step1.notMeetOurExpections" name="checkbox-8">
                    Didn't meet our expectations
                  </b-form-checkbox>
                </div>
              </template>

              <template>
                <div role="group">
                  <label for="input-live" class="mb-3 mt-1">Other</label>
                  <b-form-input id="input-live" v-model="step1.other" :state="nameState"
                    aria-describedby="input-live-help input-live-feedback" placeholder="Other" trim>
                  </b-form-input>
                </div>
              </template>

              <template>
                <div class="mt-5 mb-3">
                  <div class="text-right button-back">
                    <b-button variant="success mr-2" @click="backToFirstStep()">Back</b-button>
                    <b-button @click="showCancelPopup2('next')" variant="outline-primary mr-4"
                      :disabled="getGreyClass()">Continue</b-button>
                  </div>
                </div>
              </template>
            </div>
          </b-modal>
          <b-modal size="lg" centered v-model="showCancelPopupStep2" hide-footer="true" hide-header-close
            :no-close-on-backdrop="true" title="Confirm deletion of unused credits and saved data">
            <span class="space" @click="closePopUp2">
              <img src="@/assets/images/icons/close.svg" alt="" />
            </span>
            <div class="sub-modal Cancel-sub">
              <template>
                <div class="mt-4">
                  <b-form-checkbox v-model="step2.completelyErashed">
                    I accept that all my data found will be completely erased
                    definitively
                  </b-form-checkbox>
                </div>

                <div>
                  <b-form-checkbox v-model="step2.notAccessAnymore">
                    I accept that all will not have access anymore to Datagma,
                    even Datagma free plan
                  </b-form-checkbox>
                </div>

                <div>
                  <b-form-checkbox v-model="step2.creditLost">
                    I accept that my {{ userCredit }} credits will be lost, and
                    you will not be able to use them anymore
                  </b-form-checkbox>
                </div>
              </template>

              <template>
                <div role="group">
                  <label for="input-live">To confirm your unsubscribe, enter your current number of
                    credits that will be deleted</label>
                  <b-form-input id="input-live" type="number" v-model="step2.numberOfCredits" :state="nameState"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Enter the current number of credits that you will definitively delete" trim>
                  </b-form-input>
                </div>
              </template>

              <div class="card-content d-flex align-items-baseline pl-5 position-relative mt-4 pb-4 pt-3 ">
                <img src="@/assets/images/quick-icon.svg" class="pt-3" />

                <div>
                  <h3>
                    Want to keep your {{ userCredit }} unused credits and your
                    data found?
                  </h3>
                  <b-card-text>
                    Instead of deleting your account you still can pause your
                    subscription plan.
                  </b-card-text>

                  <b-button variant="success">Pause instead</b-button>
                </div>
              </div>

              <template>
                <div class="mt-5 mb-2">
                  <div class="text-right button-back">
                    <b-button variant="success mr-2" @click="backTosecondStep()">Back</b-button>
                    <b-button variant="outline-primary mr-4" @click="cancelSubscription"
                      :disabled="getGreyClass()">Submit</b-button>
                  </div>
                </div>
              </template>
            </div>
          </b-modal>
          <b-modal size="lg" centered v-model="showPausePopupStep" hide-footer="true" hide-header-close
            :no-close-on-backdrop="true" title="Pause account">
            <span class="space" @click="closePopUp">
              <img src="@/assets/images/icons/close.svg" alt="" />
            </span>
            <div class="sub-modal Cancel-sub">
              <p>You will be in paused account</p>

              <template>
                <div class="mt-4 px-2">
                  <b-form-checkbox v-model="pauseStep1.keepXxCredits">
                    We will still keep your {{ userCredit }} credits
                  </b-form-checkbox>
                </div>

                <div class="px-2">
                  <b-form-checkbox v-model="pauseStep1.notEraseData">
                    We will not erase your data found
                  </b-form-checkbox>
                </div>

                <div class="px-2">
                  <b-form-checkbox v-model="pauseStep1.limitedChargeFee">
                    We charge you a limited fee of 20 usd per month to keep your
                    data and credits. Once you activate back this account, we
                    will stop charging this fee
                  </b-form-checkbox>
                </div>
              </template>

              <template>
                <div class="mt-5 mb-2">
                  <div class="text-right button-back">
                    <b-button variant="success mr-2" @click="backFromPauseStep">Back</b-button>
                    <b-button variant="outline-primary mr-4" @click="pauseSubscription"
                      :disabled="getGreyClass()">Submit</b-button>
                  </div>
                </div>
              </template>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_CUSTOMERDETAIL,
  CANCEL_CUSTOMER_SUBSCRIPTION,
  // USER_CREDIT,
} from "../../../../store/constant/actions.type";
import { loadingService } from "../../services/loading.service";
import moment from "moment";
import { analyticsService } from "../../services/analytics.service";
export default {
  name: "subscription",
  data() {
    return {
      user_detail: {},
      subscriptionData: [],
      customerId: null,
      subscriptionId: null,
      showPauseOrDeletePopup: false,
      showCancelPopupStep1: false,
      showCancelPopupStep2: false,
      showPausePopupStep: false,
      selected: null,
      step1: {
        notEnoughData: false,
        tooComplicated: false,
        noTimeToOptimise: false,
        projectFinished: false,
        poorFunctionality: false,
        tooExpensive: false,
        goWithProvider: false,
        notMeetOurExpections: false,
        other: null,
      },
      step2: {
        completelyErashed: false,
        notAccessAnymore: false,
        creditLost: false,
        numberOfCredits: null,
      },
      pauseStep1: {
        keepXxCredits: false,
        notEraseData: false,
        limitedChargeFee: false,
      },
      fields: [
        {
          key: "Plan_ID",
          label: "Plan ID",
          sortable: false,
        },
        {
          key: "Plan_amount",
          label: "Plan Amount",
        },
        {
          key: "Current_term_end",
          label: "Current Term End",
        },
        {
          key: "Billed_every",
          label: "Billed Every",
        },
        {
          key: "Status",
          label: "Status",
          class: "status__btn",
        },
        {
          key: "delete",
          label: "",
          class: "cancelled-btn",
        },
      ],
    };
  },
  methods: {
    getCustomerId() {
      loadingService.setloader(true);
      this.$store.dispatch(GET_CUSTOMERDETAIL).then((data) => {
        if (data) {
          loadingService.setloader(false);
          this.subscriptionData = data.list;
          this.userCredit = parseInt(this.user_detail?.currentCredit);
        }
      });
      loadingService.setloader(false);
    },
    cancelSubscription() {
      loadingService.setloader(true);

      if (this.showCancelPopupStep2) {
        this.step2.userCredit = this.user_detail?.currentCredit;
        analyticsService.userCancelSubscription("step2", this.step2, this.user_detail);
        delete this.step2.userCredit;
      }
      const payload = {
        customerId: this.customerId,
        subscriptionId: this.subscriptionId,
      };
      this.$store
        .dispatch(CANCEL_CUSTOMER_SUBSCRIPTION, payload)
        .then((response) => {
          if (response) {
            this.closePopUp();
            this.getCustomerId();
          }
        })
        .catch((err) => {
          if (err && err.data && err.data.message) {
            loadingService.setloader(false);
            this.closePopUp();
            this.flashMessage.error({
              title: "Error Message",
              message: err.data.message,
            });
          }
        });

      loadingService.setloader(false);
    },
    pauseSubscription() {
      this.pauseStep1 = this.user_detail?.currentCredit;
      analyticsService.userCancelSubscription("pauseStep", this.pauseStep1, this.user_detail);
      delete this.pauseStep1;
      let planId = this.encryptPlanID("0-pause-subscription");
      this.$router.push({ name: "paymentLink", params: { id: planId } });
    },
    planAmount(plan) {
      if (plan) {
        return plan / 100;
      }
    },
    encryptPlanID(plan_id) {
      const encryptedText = this.$CryptoJS.AES.encrypt(
        plan_id,
        "123drgkner"
      ).toString();
      return encryptedText;
    },
    getGreyClass() {
      if (
        this.step1.notEnoughData ||
        this.step1.tooComplicated ||
        this.step1.tooComplicated ||
        this.step1.noTimeToOptimise ||
        this.step1.projectFinished ||
        this.step1.poorFunctionality ||
        this.step1.tooExpensive ||
        this.step1.goWithProvider ||
        this.step1.notMeetOurExpections ||
        this.step1.other ||
        this.step2.numberOfCredits ||
        this.pauseStep1.keepXxCredits ||
        this.pauseStep1.notEraseData ||
        this.pauseStep1.limitedChargeFee ||
        this.selected === "pauseAccount" ||
        this.selected === "deleteAccount"
      ) {
        return false;
      } else {
        return true;
      }
    },
    secondStep() {
      this.selected = null;
      this.showPauseOrDeletePopup = !this.showPauseOrDeletePopup;
      this.showCancelPopupStep1 = !this.showCancelPopupStep1;
    },
    pauseStep() {
      this.selected = null;
      this.showPauseOrDeletePopup = !this.showPauseOrDeletePopup;
      this.showPausePopupStep = !this.showPausePopupStep;
    },
    showCancelPopup2(callFor) {
      if (!this.selected) {
        this.step1 = this.user_detail?.currentCredit;
        if (callFor === "next" && this.showCancelPopupStep1) {
          analyticsService.userCancelSubscription("step1", this.step1, this.user_detail);
          this.step1 = {};
          this.showCancelPopupStep1 = !this.showCancelPopupStep1;
          this.showCancelPopupStep2 = !this.showCancelPopupStep2;
        }
      }
    },
    getDate(value) {
      if (value) {
        return moment(String(new Date(value * 1000))).format("dddd LL");
      }
    },
    splitText(text) {
      if (text) {
        const textArray = text.split("_");
        const planText = textArray.toString().replace(/,/g, " ");
        return planText;
      }
    },
    OpenCancelPopup() {
      this.showCancelPopupStep1 = !this.showCancelPopupStep1;
    },
    OpenPauseOrDeletePopup(data) {
      this.customerId = data.customer.id;
      this.subscriptionId = data.subscription.id;
      this.showPauseOrDeletePopup = !this.showPauseOrDeletePopup;
    },
    cancelPopup() {
      this.showPauseOrDeletePopup = false;
      this.step1 = {};
    },
    backToFirstStep() {
      this.step1 = {};
      this.showCancelPopupStep1 = !this.showCancelPopupStep1;
      this.showPauseOrDeletePopup = !this.showPauseOrDeletePopup;
    },
    backFromPauseStep() {
      this.pauseStep1 = {};
      this.showPausePopupStep = !this.showPausePopupStep;
      this.showPauseOrDeletePopup = !this.showPauseOrDeletePopup;
    },
    backTosecondStep() {
      this.step2 = {};
      this.showCancelPopupStep2 = !this.showCancelPopupStep2;
      this.showCancelPopupStep1 = !this.showCancelPopupStep1;
    },
    closePopUp() {
      this.showPauseOrDeletePopup = false;
      this.showCancelPopupStep1 = false;
      this.showCancelPopupStep2 = false;
      this.showPausePopupStep = false;
      this.selected = null;
      this.step1 = {};
      this.step2 = {};
      this.pauseStep1 = {};
    },
    closePopUp2() {
      let userCredit = this.user_detail?.currentCredit;
      let data = {
        userCredit,
      };
      analyticsService.userCancelSubscription("cancelStep", data, this.user_detail);
      this.closePopUp();
    },
  },

  beforeMount() {
    this.user_detail = this.$store.getters.getstoreUser;
    this.$eventBus.$emit("callCreditMethod");
  },

  mounted() {
    this.getCustomerId();
  },

  computed: {},
};
</script>

<style>
.subscription .subscription-table {
  border: 1px solid #003172;
}

.subscription .subscription-table td {
  border-top: none;
  font-size: 13px;
  padding-top: 24px;
}

.subscription .subscription-table tr,
.subscription .table thead th {
  border-bottom: 1px solid #003172;
}

.subscription .subscription-table thead {
  background-color: #eaedf0;
}

.subscription .table th,
.table td {
  border-top: 1px solid #003172;
}

.subscription .subscription-table .status-btn {
  font-size: 13px;
  text-transform: uppercase;
  padding: 5px 8px;
  border-radius: 4px;
  color: #fff;
}

td.cancelled-btn {
  display: flex;
  gap: 4px;
}

.cancelled-btn button.btn.btn-primary {
  width: 167px;
  font-size: 12px;
  height: 42px;
  padding: 0;
  margin-top: -12px;
  text-transform: uppercase;
}

.modal_title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.sub-modal {
  max-width: 768px;
  width: 100%;
  margin: auto;
  /* border: 1px solid #00000057; */
  border-radius: 7px;
  /* box-shadow: 4px 2px 4px rgb(0 0 0 / 5%); */
}

.modal_title h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: inherit;
}

.custom-control h4 {
  font-weight: 600;
  margin-left: 16px;
  font-size: 18px;
}

.custom-control p {
  font-size: 16px;
  font-weight: 400;
  margin-left: 16px;
}

.sub-modal button.btn.btn-success {
  background: transparent;
  color: #003172;
  border: 1px solid #003172;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  padding: 8px 27px;
}

.sub-modal button.btn.btn-outline-primary {
  border: 1px solid #ff0052;
  color: #ff0052;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  padding: 8px 30px;
}

.sub-modal .btn-outline-primary:hover {
  background-color: #ffff;
}

.button-back {
  border-top: 3px solid #f7f8fa;
  padding-top: 12px;
  margin: 62px -16px 0px;
}

.paragrpah-text p {
  font-size: 14px;
  font-weight: 400;
  color: #003172;
}

.sub-modal.Cancel-sub h6 {
  font-size: 16px;
  color: #003172;
  font-weight: 500;
}

.custom-control-label {
  font-size: 16px;
  line-height: 24px;
}

.sub-modal .custom-control-label::after {
  top: -0px;
  width: 20px;
  height: 21px;
  left: -26px;
}

.sub-modal .custom-control-label::before {
  background-image: linear-gradient(180deg, #ffffff 0%, #fff 100%);
  top: 2px;
  width: 24px;
  height: 24px;
}

.card.card-sub {
  background-color: #fafafa !important;
  border: none;
}

.card-content h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: inherit;
}

p.card-text {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: #10234c;
}

.card-content {
  background: #f7f6fd;
  border-radius: 8px;
}

.card-content img {
  position: absolute;
  top: 0px;
  left: 12px;
}

.sub-modal.Cancel-sub .custom-control-label::before {
  width: 16px;
  height: 16px;
}

.sub-modal.Cancel-sub a.btn.btn-success {
  font-size: 14px;
  border-radius: 8px;
  font-weight: 600;
  margin-top: 4px;
}

.subscription .subscription-table th {
  font-size: 14px;
}

.sub-modal.pause-sub .custom-control-label::after {
  top: -2px;
  width: 31px;
  height: 31px;
  left: -28px;
}

.modal-header {
  border-bottom: none;
  margin-top: 19px;
}

.modal-header .modal-title img {
  font-size: 26px;
  font-weight: 600;
}

.modal.show .modal-dialog span.space img {
  position: absolute;
  top: -48px;
  right: 30px;
  cursor: pointer;
}

h5.modal-title {
  font-size: 26px;
  font-weight: 600;
}

.modal-dialog .custom-checkbox {
  display: block;
}

.modal-content .custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("~@/assets/images/icons/sub-checkbox.svg");
}

.modal-content .custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-image: linear-gradient(180deg,
      rgb(255 255 255) 0%,
      rgb(255 255 255) 100%);
  box-shadow: inset 0px 1.05882px 7.05882px rgba(0, 0, 0, 0.15);
  background-color: transparent;
  border: 2px solid #2764b5;
}

.modal-content .sub-modal.pause-sub .custom-radio .custom-control-label::after {
  top: 2px;
  width: 24px;
  height: 24px;
  left: -24px;
}
</style>
